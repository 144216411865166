<template>
  <div>
    <el-form
      :model="queryParams"
      ref="queryFormRef"
      size="small"
      :inline="true"
      label-width="88px"
    >
      <el-form-item label="会员名称" prop="ClientName">
        <el-input
          v-model="queryParams.ClientName"
          placeholder="请输入会员名称"
          clearable
          @keyup.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="会员手机号" prop="ClientPhone">
        <el-input
          v-model="queryParams.ClientPhone"
          placeholder="请输入会员手机号"
          clearable
          @keyup.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="城市" prop="city">
        <el-input
          v-model="queryParams.city"
          placeholder="请输入城市"
          clearable
          @keyup.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="门店名称" prop="shopName">
        <el-input
          v-model="queryParams.shopName"
          placeholder="请输入门店名称"
          clearable
          @keyup.enter="handleQuery"
        />
      </el-form-item>

      <el-form-item label="分公司" prop="largeArea">
        <el-input
          v-model="queryParams.largeArea"
          placeholder="请输入分公司名称"
          clearable
          @keyup.enter="handleQuery"
        />
      </el-form-item>
      <!-- <el-form-item label="小区" prop="smallArea">
        <el-input
          v-model="queryParams.smallArea"
          placeholder="请输入小区名称"
          clearable
          @keyup.enter="handleQuery"
        />
      </el-form-item> -->

      <el-form-item label="跟进人" prop="followUper">
        <el-input
          v-model="queryParams.followUper"
          placeholder="请输入跟进人"
          clearable
          @keyup.enter="handleQuery"
        />
      </el-form-item>
      <el-form-item label="跟进内容" prop="followUpContent">
        <el-input
          v-model="queryParams.followUpContent"
          placeholder="请输入跟进内容"
          clearable
          @keyup.enter="handleQuery"
        />
      </el-form-item>

      <el-form-item label="跟进状态" prop="followUpStatus">
        <el-select
          v-model="queryParams.followUpStatus"
          placeholder="请选择跟进状态"
          clearable
        >
          <el-option :key="0" :value="'0'" label="未完成" />
          <el-option :key="1" :value="'1'" label="处理中" />
          <el-option :key="2" :value="'2'" label="已完成" />
        </el-select>
      </el-form-item>

      <el-form-item label="开始时间" prop="StartTime">
        <el-date-picker
          v-model="queryParams.StartTime"
          type="date"
          value-format="YYYY-MM-DD 00:00:00"
          placeholder="开始日期"
        >
        </el-date-picker>
      </el-form-item>
      <el-form-item label="结束时间" prop="EndTime">
        <el-date-picker
          v-model="queryParams.EndTime"
          value-format="YYYY-MM-DD 23:59:59"
          type="date"
          placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button @click="handleQuery" icon="el-icon-search" type="primary"
          >搜索</el-button
        >
        <el-button @click="resetQuery" icon="el-icon-refresh">重置</el-button>
        <el-button
          @click="exportExcel()"
          icon="el-icon-download"
          type="primary"
          :loading="exportLoading"
          >导出</el-button
        >
      </el-form-item>
    </el-form>
    <!--2. table表格的内容-->
    <el-table
      id="table"
      border
      stripe
      :data="data.table"
      style="width: 100%"
      v-loading="loading"
    >
      <el-table-column prop="id" label="ID" v-if="false"> </el-table-column>
      <!--隐藏列-->
      <el-table-column
        type="index"
        :index="indexMethod"
        width="50"
        style="text-align: center"
      >
      </el-table-column>
      <el-table-column prop="clientName" label="会员姓名"> </el-table-column>
      <el-table-column prop="clientPhone" label="会员手机" width="110">
      </el-table-column>
      <el-table-column prop="shopName" label="门店" width="150">
      </el-table-column>
      <el-table-column prop="largeArea" label="分公司" width="150">
      </el-table-column>
      <!-- <el-table-column prop="smallArea" label="小区" width="150">
      </el-table-column> -->
      <el-table-column
        prop="types"
        label="投诉类型"
        width="190"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="problem"
        label="投诉问题"
        width="150"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column prop="followUpStatus" label="跟进状态">
      </el-table-column>
      <el-table-column prop="followUper" label="跟进人" show-overflow-tooltip>
      </el-table-column>
      <el-table-column
        prop="followUpContent"
        label="跟进内容"
        width="150"
        show-overflow-tooltip
      >
      </el-table-column>
      <el-table-column
        prop="creationTime"
        label="投诉时间"
        show-overflow-tooltip
        width="170"
      >
      </el-table-column>
      <el-table-column
        label="图片"
        prop="headImgURL"
        align="center"
        width="150"
        show-overflow-tooltip
      >
        <template v-slot="scope">
          <el-image
            v-for="(item, index) in scope.row.complaintImg.filter(
              (e) => e.trim().length > 0
            )"
            :key="index"
            style="
              width: 50px;
              height: 50px;
              margin-bottom: -4px;
              margin-right: 10px;
            "
            :src="item"
            :preview-src-list="scope.row.complaintImg"
          >
          </el-image>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="180">
        <!--操作固定列 -->
        <template #default="scope">
          <el-button size="small" @click="openEdit(scope.row)" type="text"
            >处理</el-button
          >
          <!-- <el-button size="small" @click="pushMessage(scope.row)" type="primary"
            >跟进消息推送</el-button
          > -->
          <el-button size="small" @click="showMemberInfo(scope.row)" type="text"
            >客户资料</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <!-- 3.分页 -->
    <el-pagination
      class="fenye"
      @current-change="handleCurrentChange"
      :current-page="queryParams.page"
      :page-size="queryParams.pageSize"
      prev-text="&nbsp;&nbsp;&nbsp;上一页&nbsp;&nbsp;&nbsp;"
      next-text="&nbsp;&nbsp;&nbsp;下一页&nbsp;&nbsp;&nbsp;"
      background
      layout="total, prev, pager, next, jumper"
      :total="data.total"
    >
    </el-pagination>

    <!-- 表单弹窗：更新处理结果 -->
    <CustomerComplaintForm ref="formRef" @success="getList" />
  </div>
</template>

<!-- 本页的js -->
<script>
import { onMounted, reactive, ref } from "vue";
import { getComplaintList, exportComplaint } from "../http/complaint";

import * as XLSX from "xlsx";
import FileSaver from "file-saver";
import CustomerComplaintForm from "./components/CustomerComplaintForm.vue";
import { useRouter } from "vue-router";
//import { ElMessage } from "element-plus";

export default {
  name: "Complaint",
  components: { CustomerComplaintForm },
  setup() {
    const loading = ref(true);
    //声明数据
    let data = reactive({
      table: [],
      pages: 0,
      total: 0,
    });

    //声明查询条件
    let queryParams = reactive({
      ClientName: undefined,
      ClientPhone: undefined,
      page: 1,
      pageSize: 8,
      ShopName: "",
      StartTime: "",
      EndTime: "",
      followUpStatus: undefined, // 跟进状态
      followUper: undefined, // 跟进人
      largeArea: undefined,
      smallArea: undefined,
      city: undefined,
      followUpContent: undefined, // 跟进内容
    });

    const queryFormRef = ref();

    let resetQuery = () => {
      queryFormRef.value.resetFields();
      handleQuery();
    };

    const router = useRouter();

    //导出表格为excel
    let exportExcel = () => {
      //开始导出
      exportData();
    };

    const exportLoading = ref(false);

    let exportData = async () => {
      exportLoading.value = true;
      try {
        let res = await exportComplaint(queryParams);
        let reg = /\\/g;
        let replaceAfter = res.eValues.replace(reg, "");
        var jsondata = JSON.parse(replaceAfter);
        let time = new Date().getTime();
        const workbook = XLSX.utils.book_new();
        const worksheet = XLSX.utils.json_to_sheet(jsondata);
        XLSX.utils.book_append_sheet(workbook, worksheet, "data");
        XLSX.writeFile(workbook, "客户投诉表_" + time + ".xlsx");
      } finally {
        exportLoading.value = false;
      }
    };

    let handleQuery = () => {
      getList();
    };

    let indexMethod = (index) => {
      return index + (queryParams.page - 1) * queryParams.pageSize + 1;
    };

    let getList = async () => {
      loading.value = true;
      try {
        let arr = await getComplaintList(queryParams);
        data.total = arr.totalCount;
        data.table = arr.items;
      } finally {
        loading.value = false;
      }
    };

    //点击页码获取数据
    let handleCurrentChange = (index) => {
      queryParams.page = index;
      getList();
    };

    const formRef = ref();

    // 处理结果
    const openEdit = (row) => {
      formRef.value.open(row);
    };

    // 跟进消息推送
    const pushMessage = async (row) => {};

    // 查看客户资料
    const showMemberInfo = async (row) => {
      router.push({
        name: "MemberDetail",
        query: { memberId: row.memberId || row.memberID },
      });
    };

    onMounted(() => {
      //初始化时，获取初始化数据
      getList(queryParams);
    });

    return {
      loading,
      exportLoading,
      data,
      queryFormRef,
      exportExcel,
      exportData,
      handleQuery,
      resetQuery,
      handleCurrentChange,
      queryParams,
      indexMethod,
      pushMessage,
      showMemberInfo,
      openEdit,
      formRef,
      getList,
    };
  },
};
</script>

<!-- 本页的css -->
<style lang="scss" scoped>
.fenye {
  margin-top: 20px;
}

.row-overflow {
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 1; //行数
  -webkit-box-orient: vertical;
}
</style>
